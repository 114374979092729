<template>
  <div>
    <b-title>
      <download-icon class="icon" />
      {{ $t("download.title") }}
    </b-title>
    <b-information>
      <div
        v-html="
          $t('download.information', {
            link: $localePath({ name: 'FormSubmission' }),
          })
        "
      ></div>
    </b-information>
    <b-container class="body">
      <b-alert class="m-b-20" v-if="showAlert" />
      <b-dialog v-model="showDialog" class="text-center">
        <template #activator>
          <b-form-row :validation="v$.product" class="product-select">
            <b-select
              :options="productsList"
              v-model="v$.product.$model"
              :placeholder="$t('download.select_product')"
              @change="onProductChange"
            ></b-select>
          </b-form-row>
          <b-btn @click="onSubmit">
            {{ $t("download.submit") }}
          </b-btn>
        </template>
        <strong class="product-name">
          {{
            $t("download.pop_in_title", {
              product: productSelected.lang[$i18n.locale].name,
            })
          }}
        </strong>
        <b-btn
          v-if="productSelected"
          :href="productSelected.lang[$i18n.locale].bia"
          target="_blank"
          download
          class="m-t-10"
        >
          {{ $t("download.dialog_form") }}
        </b-btn>
        <b-btn
          :href="qmList[$i18n.locale]"
          target="_blank"
          download
          class="m-t-10"
        >
          {{ $t("download.dialog_qm") }}
        </b-btn>
      </b-dialog>
    </b-container>
  </div>
</template>

<script lang="ts">
import DownloadIcon from "@/components/icon/DownloadIcon.vue";
import { computed, defineComponent, onMounted, ref } from "vue";
import useDownloadForm from "@/forms/useDownloadForm";
import dataHttp, { ProductResponse } from "@/http/dataHttp";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useSubscriber } from "@/use/useSubscriber";

export default defineComponent({
  components: { DownloadIcon },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { v$, fields } = useDownloadForm();
    const { locale } = useI18n();
    const showAlert = ref<boolean>(false);
    const showDialog = ref<boolean>(false);
    const qmList = ref<{ en: string; fr: string }>();
    const productResponse = ref<ProductResponse[]>([]);
    const productsList = ref<{ key: string; value: string }[]>([]);
    const subscriber = useSubscriber();

    /** Retourne les informations du produit sélectionné */
    const productSelected = computed(() => {
      return productResponse.value.find((productResponse: ProductResponse) => {
        return productResponse.id === fields.product;
      });
    });

    /** Redirige le client vers la bonne page en fonction des param en url */
    const queryManager = () => {
      if (route.query.broker) {
        subscriber.setBroker(String(route.query.broker));
      }

      if (route.query.ref && route.query.step) {
        if (route.query.step == "BIA") {
          return router.push({
            name: "ComplementBia",
            params: {
              locale: locale.value,
              reference: String(route.query.ref),
            },
          });
        }

        if (route.query.step == "QM") {
          return router.push({
            name: "ComplementQm",
            params: {
              locale: locale.value,
              reference: String(route.query.ref),
            },
          });
        }
      }
    };

    onMounted(async () => {
      queryManager();
      const productQuery = route.query.product;

      try {
        const { data: qmFetched } = await dataHttp.getQm();
        qmList.value = qmFetched;

        const { data: productsFetched } = await dataHttp.getProducts();
        productResponse.value = productsFetched;
        productsFetched.forEach((product: ProductResponse) => {
          productsList.value.push({
            key: product.id,
            value: product.lang[locale.value as "fr" | "en"].name,
          });

          if (productQuery === product.id) {
            fields.product = product.id;
            showDialog.value = true;
          }
        });
      } catch (_response) {
        showAlert.value = true;
      }
    });

    const onProductChange = () => {
      router.push({
        query: { ...route.query, product: fields.product },
      });
    };

    const onSubmit = () => {
      v$.value.$touch();
      if (v$.value.$invalid) return;
      showDialog.value = true;
    };

    return {
      v$,
      fields,
      showAlert,
      showDialog,
      qmList,
      productsList,
      productSelected,
      onSubmit,
      onProductChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.product-name {
  display: block;
  margin-bottom: 20px;
  font-size: var(--font-xl);
  color: var(--title);
}

.product-select {
  min-width: 330px;
}
</style>
