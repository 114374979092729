import { reactive, Ref } from "vue";
import useVuelidate, { Validation, ValidationArgs } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export interface DownloadFormInterface {
  product: string;
}

export interface FormInterface {
  v$: Ref<Validation<ValidationArgs>>;
  fields: DownloadFormInterface;
}

const form = (): FormInterface => {
  const state = reactive<DownloadFormInterface>({
    product: "",
  });

  const rules: ValidationArgs = {
    product: {
      required: helpers.withMessage("required", required),
    },
  };

  const v$ = useVuelidate(rules, state);

  return { v$, fields: state };
};

export default form;
