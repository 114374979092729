import http from "@/http";
import { AxiosPromise } from "axios";

interface LangProductResponse {
  bia: string;
  name: string;
}

export interface ProductResponse {
  id: string;
  lang: {
    en: LangProductResponse;
    fr: LangProductResponse;
  };
}

export default {
  getProducts(): AxiosPromise {
    return http.get("/api/products");
  },
  getQm(): AxiosPromise {
    return http.get("/api/qm");
  },
};
